var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-photo" },
    [
      _vm.url
        ? _c(
            "div",
            {
              staticClass: "uploaded-trigger",
              attrs: { disabled: _vm.disabled }
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("img", {
                  staticClass: "info__thumbnail",
                  attrs: { src: _vm.url, alt: "photo" }
                })
              ]),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("a-icon", {
                    staticClass: "actions__preview",
                    staticStyle: { "margin-right": "8px" },
                    attrs: { type: "eye" },
                    on: {
                      click: function() {
                        _vm.previewVisible = true
                      }
                    }
                  }),
                  _c("a-icon", {
                    staticClass: "actions__delete",
                    attrs: { type: "delete" },
                    on: {
                      click: function() {
                        return _vm.url
                          ? _vm.$emit("delete")
                          : (_vm.visible = !_vm.visible)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _c(
            "button",
            {
              staticClass: "upload-trigger",
              attrs: { disabled: _vm.disabled },
              on: {
                click: function() {
                  _vm.visible = !_vm.visible
                }
              }
            },
            [
              _c("a-icon", { staticClass: "mr-xs", attrs: { type: "upload" } }),
              _c("span", [_vm._v(_vm._s(_vm.$t("Upload")))])
            ],
            1
          ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.visible },
          on: {
            ok: _vm.handleOk,
            cancel: function() {
              return (_vm.visible = false)
            }
          }
        },
        [
          _c("br"),
          _c(
            "a-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeKey,
                callback: function($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey"
              }
            },
            [
              _c(
                "a-tab-pane",
                {
                  key: "upload",
                  attrs: { tab: _vm.$t("Upload"), disabled: !!_vm.urlInput }
                },
                [
                  _c("a-spin", { attrs: { spinning: _vm.loading } }, [
                    _c(
                      "div",
                      { staticClass: "spin-content" },
                      [
                        _c(
                          "a-upload-dragger",
                          {
                            attrs: {
                              name: "file",
                              accept:
                                ".png, .jpg, .jpeg, .gif, .png, .webp, .ico",
                              "file-list": [],
                              "custom-request": _vm.uploadS3,
                              "before-upload": _vm.beforeUpload
                            }
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "ant-upload-drag-icon" },
                              [_c("a-icon", { attrs: { type: "inbox" } })],
                              1
                            ),
                            _c("p", { staticClass: "ant-upload-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "Click or drag file to this area to upload"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("br"),
                  _vm.urlImport
                    ? _c(
                        "div",
                        {
                          staticClass: "content-between",
                          staticStyle: {
                            border: "1px solid #e8e8e8",
                            padding: "8px",
                            "border-radius": "4px"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "100px",
                              height: "50px",
                              "object-fit": "cover"
                            },
                            attrs: { src: _vm.urlImport, alt: "alt image" }
                          }),
                          _c("a-button", {
                            attrs: { icon: "delete" },
                            on: {
                              click: function() {
                                return (_vm.urlImport = "")
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "by_url",
                  attrs: { tab: _vm.$t("By URL"), disabled: !!_vm.urlImport }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t("Please input your image url"),
                      size: "large"
                    },
                    model: {
                      value: _vm.urlInput,
                      callback: function($$v) {
                        _vm.urlInput = $$v
                      },
                      expression: "urlInput"
                    }
                  }),
                  _vm.errorMessageImage
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "margin-top": "4px",
                            "font-size": "0.8rem"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.errorMessageImage) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: {
            cancel: function() {
              _vm.previewVisible = false
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.url }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
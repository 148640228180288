//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { v4 as uuidv4 } from 'uuid';
import { uploadFileToS3 } from '@/api/s3';

export default {
  props: {
    url: { type: String, default: '' },
    folderNameS3: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      visible: false,
      urlImport: '',
      urlInput: '',
      activeKey: 'upload',
      loading: false,
      previewVisible: false
    };
  },

  watch: {
    url: {
      handler() {
        this.urlImport = this.url;
      },
      immediate: true
    }
  },

  computed: {
    errorMessageImage() {
      const imgPattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|webp|ico))/g;
      const svgOnlyPattern = /(http)?s?:?(\/\/[^"']*\.(?:svg))/g;

      if (!this.urlInput || this.urlInput.match(imgPattern)) return '';

      if (this.urlInput.match(svgOnlyPattern)) {
        return this.$t('SVG image is not supported!');
      }

      return this.$t('Image URL is invalid.');
    }
  },

  methods: {
    handleOk() {
      if (this.activeKey === 'by_url' && !this.errorMessageImage) {
        this.$emit('upload', this.urlInput);
      } else if (this.activeKey === 'upload') {
        this.$emit('upload', this.urlImport);
      }
      this.visible = false;
    },
    async uploadS3({ file, onSuccess, onError }) {
      if (file.type === 'image/svg+xml') {
        return false;
      }
      try {
        this.loading = true;
        const key = `${this.folderNameS3}/${uuidv4()}_${file.name}`;
        this.urlImport = await uploadFileToS3({ key, contentType: file.type, file });
        onSuccess(null, file);
      } catch (error) {
        console.log(error);
        onError(error);
      } finally {
        this.loading = false;
      }
    },
    async beforeUpload(file) {
      // avoid if file is svg in windows
      if (file.type === 'image/svg+xml') {
        this.$notification.warning({
          message: this.$t('SVG image is not supported!')
        });
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$notification.warning({
          message: 'アップロードできるのは、1ファイルあたり最大10MBまで、。'
        });
      }
      return isLt2M;
    }
  }
};
